<template>
  <b-navbar toggleable="lg">
    <b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item v-if="tenants.length < 2">
          <img style="max-height: 36px;" v-if="currentTenant !== null" :src="currentTenantLogo" :alt="`${currentTenant.name} logo`" />
        </b-nav-item>
        <b-nav-item-dropdown menu-class="customer-selector" v-if="tenants.length > 1" ref="tenant-selection">
          <template style="display: inline-block" v-slot:button-content>
            <img style="max-height: 36px;" v-if="currentTenant !== null" :src="currentTenantLogo" :alt="`${currentTenant.name} logo`" />
          </template>
          <div class="py-4 px-4">
            <div class="text-center"><img style="max-height: 36px;" :src="selectedTenant ? currentTenantLogo : null" :alt="selectedTenant ? `${selectedTenant.name} logo` : null" /></div>

            <hr />

            <p>Select customer:</p>

            <b-form-select v-model="selectedTenant" @change="switchTenantAsync">
              <!-- <template v-slot:first>
                <b-form-select-option :value="null" disabled>Opus Nebula</b-form-select-option>
              </template> -->

              <b-form-select-option v-for="tenant in tenants" :key="tenant.id" :value="tenant">{{ tenant.name }}</b-form-select-option>
            </b-form-select>
          </div>
          <!-- <div class="p-4 text-center">
            <b-btn variant="primary" @click="switchTenantAsync(selectedTenant)">Switch customer</b-btn>
          </div> -->
          <!-- <b-dropdown-item href="#"><img src="../../assets/on-logo.png" alt />   </b-dropdown-item>
            <b-dropdown-item href="#">   </b-dropdown-item>
             -->
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" variant="dark"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="hasReportGroups">
        <b-nav-item :to="{ name: 'home', params: buildParams('home') }">Home</b-nav-item>
        <b-nav-item :to="{ name: 'summary', params: buildParams('summary') }">Summary</b-nav-item>
        <b-nav-item :to="{ name: 'configuration', params: buildParams('configuration') }">Configuration</b-nav-item>
        <b-nav-item :to="{ name: 'data', params: buildParams('data') }">Data</b-nav-item>
        <b-nav-item :to="{ name: 'workflow', params: buildParams('workflow') }">Workflow</b-nav-item>
        <b-nav-item :to="{ name: 'document-library', params: buildParams('document-library') }">Document library</b-nav-item>
      </b-navbar-nav>

      <notification-tray />

      <b-navbar-nav>
        <b-nav-item-dropdown right toggle-class="d-flex align-items-center">
          <template v-slot:button-content>
            <b-avatar size="2rem" variant="secondary" v-if="user" :text="avatarText" />
            <!-- <avatar class="is-small mr-2" v-if="user" :first-name="user.profile.given_name" :last-name="user.profile.family_name" /> -->
            <div class="text-right ml-1 m-2" style="display: block;">
              <div style="line-height: 1rem;" v-if="user">{{ currentUser.name }}</div>
              <div style="line-height: 1rem; font-size: 0.8rem;" v-if="currentTenant !== null">{{ uppercase(currentTenant.name) }}</div>
            </div>
          </template>
          <b-dropdown-form v-if="tenants.length > 1">
            <div style="min-width: 20rem;" class="my-2">
              <p>Switch tenant:</p>
              <b-form-select v-model="selectedTenant" @change="switchTenantAsync(selectedTenant)">
                <!-- <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Opus Nebula</b-form-select-option>
                </template> -->

                <b-form-select-option v-for="tenant in tenants" :key="tenant.id" :value="tenant">{{ tenant.name }}</b-form-select-option>
              </b-form-select>
            </div>
            <!-- <div class="py-3 text-center">
              <b-btn variant="link" @click="switchTenantAsync(selectedTenant)">Switch tenant</b-btn>
            </div> -->
          </b-dropdown-form>
          <b-dropdown-divider v-if="tenants.length > 1"></b-dropdown-divider>
          <b-dropdown-item v-if="canViewLanguages" :to="{ name: 'language-maintenance', params: buildParams('language-maintenance') }">Language maintenance</b-dropdown-item>
          <b-dropdown-item v-if="false && canManageSettings" href="#">Settings</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'support' }">Support</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'help' }">Help</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-if="currentUser !== null && currentUser.roles.administrator" :href="`${buildUsersUrl}`">Users</b-dropdown-item>
          <b-dropdown-item v-if="currentUser !== null && currentUser.isStandardLogin" :href="`${buildBaseUrl}/change-password`">Change password</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Log out</b-dropdown-item>
          <b-dropdown-header v-if="environment !== null">{{ environmentDescription }}</b-dropdown-header>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { User } from 'oidc-client';
import { namespace } from 'vuex-class';
import { BDropdown } from 'bootstrap-vue';
import UserManager from '@/services/user-manager/user-manager';
import WebsocketManager from '@/services/notifications/websocket-manager';
import { kebabcase, uppercase } from '@/utilities/text.utils';
import { date as toValuationDate } from '../../filters/date';
import { Tenant } from '../../store/tenant/state';
import VueRouter, { RawLocation } from 'vue-router';
import NotificationTray from './notification-tray/notification-tray.vue';
import { baseUrl } from '../../base-url';
import { State as WorkflowState } from '@/store/workflow/state';
import { State as DocumentLibraryState } from '@/store/document-library/state';
import { Dictionary } from 'vue-router/types/router';

const tenantModule = namespace('tenant');
const userModule = namespace('user');
const environmentModule = namespace('environment');

@Component({
  components: {
    NotificationTray,
  },
})
export default class Navbar extends Mixins(BvToastMixin) {
  private tenant: Tenant | null = null;
  private user: User | null = null;
  private previousTenant: Tenant | null = null;
  @tenantModule.Getter private tenants!: Array<Tenant>;
  @userModule.Getter('current') private currentUser!: { name: string, email: string, currentTenantId: number | null, isStandardLogin: boolean };
  @tenantModule.Getter('current') private currentTenant!: Tenant;
  @tenantModule.Getter('currentLogo') private currentTenantLogo!: string;
  @tenantModule.Getter('canViewLanguages') private canViewLanguages!: boolean;
  @tenantModule.Getter('canManageLanguages') private canManageLanguages!: boolean;
  @tenantModule.Getter('canManageSettings') private canManageSettings!: boolean;
  @environmentModule.Getter('current') private environment!: { environment: string, version: string };

  uppercase = uppercase;

  private get selectedTenant(): Tenant | null {
    if (this.tenants == null || this.tenants.length < 0) {
      return null;
    }

    return this.tenant;
  }

  private set selectedTenant(tenant: Tenant | null) {
    this.tenant = tenant;
  }

  private get hasReportGroups(): boolean {
    return this.currentTenant.reportGroups.length > 0;
  }

  private get avatarText(): string {
    if (this.currentUser === null) {
      return '';
    }

    if (this.user!.profile.given_name && this.user!.profile.family_name) {
      return `${this.user!.profile.given_name[0].toUpperCase()}${this.user!.profile.family_name[0].toUpperCase()}`;
    }

    const parts = this.currentUser.name.split(' ');

    if (parts.length > 1) {
      return `${parts[0][0]?.toUpperCase()}${parts[1][0]?.toUpperCase()}`;
    }

    return `${parts[0][0]?.toUpperCase()}`;
  }

  public get environmentDescription() {
    if (this.environment === null) {
      return '';
    }

    if (location.host.includes('localhost')) {
      if (this.environment!.version !== null) {
        return `Local – ${this.environment!.version}`;
      } else {
        return 'Local';
      }
    }

    if (this.environment!.version !== null) {
      return `${this.environment!.environment} – ${this.environment!.version}`;
    }

    return `${this.environment!.environment}`;
  }

  private get baseUrl(): string {
    return baseUrl;
  }

  @Watch('currentTenant')
  private async onTenantChanged(tenant: Tenant, from: Tenant) {
    this.selectedTenant = tenant;
    await WebsocketManager.onTenantSwitched(from, tenant);
  }

  public async switchTenantAsync(tenant: Tenant) {
    // NOTE(Dan): Clear the selected document before navifating, otherwise if you're on the document library,
    //            with a document selected, we re-search for the document id in the url, and revert back to that tenant
    //            if you have access to multiple tenants.
    this.$store.commit('documentLibrary/setSelectedDocumentId', null);
    this.$store.commit('documentLibrary/clearPages');

    this.previousTenant = { ...this.currentTenant };

    await this.$store.dispatch('tenant/setCurrentTenantAsync', tenant);
    this.$store.commit('notification/clearAll');

    this.showSuccessToast(`Tenant successfully switched to '${tenant.name}'.`);

    const dropdown = this.$refs['tenant-selection'] as BDropdown;
    dropdown.hide();

    const { valuationDate, reportGroup, reportId, option, documentId, ...params } = this.$route.params;

    // NOTE(Dan): For routes which don't contain the tenant id will error if switching tenant due to duplicate navigations
    //            We should probably resolve the new reoute and compare, if they are different, proceed to push the new route
    //            else, we should probably just explicitly set the current tenant through the store
    //            (the same way the router beforeEach hook catches the change from the route)
    const routeName = this.$route.name === 'setup-required' || this.$route.name === 'forbidden' || this.$route.name === 'error' ? 'home' : this.$route.name!;
    const newLocation: RawLocation = { name: routeName, params: { ...params, tenant: kebabcase(tenant.name) } };
    const resolved = this.$router.resolve(newLocation);

    if (this.$route.path !== resolved.href) {
      try {
        await this.$router.push(newLocation);
      } catch (e) {
        // NOTE(Dan): In the router beforeEach we may get redirected to a different URL, e.g. we may calculate a new route based on configuration for the users initial page
        if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
          return;
        }

        throw e;
      }
    } else {
      this.$store.commit('tenant/setCurrentTenant', tenant);
    }
  }

  public async logout(): Promise<void> {
    await UserManager.signoutRedirect();
  }

  public async mounted(): Promise<void> {
    const user = await UserManager.getUser();
    this.user = user;

    if (this.tenants != null && this.tenants.length > 0) {
      this.tenant = this.tenants.find((t) => t.id === this.currentUser.currentTenantId) || null;
    }
  }

  private buildParams(routeName: string): {} {
    if (this.tenant === null) {
      return this.$route.params;
    }

    if (this.tenant!.reportGroups.length < 1) {
      return { ...this.$route.params };
    }

    const globalReportGroup = this.tenant!.reportGroups.find((rg) => rg.active.all) || this.tenant!.reportGroups[0] || null;
    const documentLibraryReportGroup = this.tenant!.reportGroups.find((rg) => rg.active.documentLibrary) || globalReportGroup || this.tenant!.reportGroups[0] || null;
    const configurationReportGroup = this.tenant!.reportGroups.find((rg) => rg.active.configuration) || globalReportGroup || this.tenant!.reportGroups[0] || null;
    const result: Dictionary<string> = { ...this.$route.params, tenant: this.$route.params.tenant, reportGroup: kebabcase(globalReportGroup.name) };

    switch (routeName) {
      case 'document-library': {
        result.reportGroup = kebabcase(documentLibraryReportGroup.name);

        if (this.$store.state.documentLibrary.current === null) {
          break;
        }

        const state = this.$store.state.documentLibrary as DocumentLibraryState;
        const selectedDocumentId = state.selectedDocumentId || undefined;

        if (selectedDocumentId !== undefined) {
          result.documentId = selectedDocumentId;
        }
        break;
      }
      case 'configuration': {
        result.reportGroup = kebabcase(configurationReportGroup.name);
        break;
      }
      case 'workflow': {
        result.reportGroup = kebabcase(globalReportGroup.name);

        if (this.$store.state.workflow.current === null) {
          break;
        }

        const state = this.$store.state.workflow as WorkflowState;
        const current = this.$store.state.workflow.current;
        const date = state.selectedValuationDate!;
        const option = state.currentOption!;
        const reportId = state.selectedReportId || undefined;

        result.valuationDate = kebabcase(toValuationDate(date.displayDate, current.dateFormat));
        result.option = kebabcase(option.name);

        if (reportId !== undefined) {
          result.reportId = reportId.toString();
        }

        break;
      }
      case 'language-maintenance':
        if (this.$route.params.phrase !== undefined) {
          delete this.$route.params.phrase;
        }

        break;
    }

    return { ...this.$route.params, ...result };
  }

  private get buildUsersUrl(): string {
    const origin = window.location.origin;

    return `${this.buildBaseUrl}/users?ReturnUrl=${origin}`;
  }

  private get buildBaseUrl(): string {
    let host = window.location.host;

    if (host.includes('.azurewebsites.net')) {
      host = `https://${host.replace('webapp', 'identity')}`;
    } else if (host.includes('localhost')) {
      host = `${process.env.VUE_APP_AUTHORITY_URL}`;
    } else {
      host = `https://id.${host}`;
    }

    return host;
  }
}
</script>

<template>
  <div>
    <filter-bar />
    <div class="container-fluid">
      <div class="row mt-4" v-if="isReportGroupSettings">
        <div class="col">
          <b-alert show variant="warning">
            <div class="d-flex flex-wrap align-items-center">
              <div class="col-auto px-0">
                <div class="workflow-report-issue-type is-warning" style="background-color: #856404">
                  <div class="text text-white">!</div>
                </div>
              </div>
              <div class="col">
                Changing report group settings will impact all <strong>{{ currentReportGroup.name }}</strong> reports
              </div>
            </div>
          </b-alert>
        </div>
      </div>
      <div class="row mt-2" :class="{ 'mt-2': isReportGroupSettings, 'mt-4': !isReportGroupSettings }">
        <div class="col-8">
          <b-tabs class="configuration-tabs" v-if="current !== null" v-model="currentTabIndex" @input="currentTabChanged" @changed="onTabsChanged" @activate-tab="onTabActivated">
            <b-tab v-if="showSchedule" :title="current.entityType ? `${current.entityType} schedule` : 'Schedule'" :title-link-attributes="{ href: buildTabUrl('schedule') }">
              <schedule-table v-if="entitySchedule !== null" />
            </b-tab>
            <b-tab v-if="showContentControl" title="Content control" :title-link-attributes="{ href: buildTabUrl('content-control') }">
              <content-control-table />
            </b-tab>
            <b-tab v-if="showSettings" :title="`${current && current.entityType} settings`" :title-link-attributes="{ href: buildTabUrl('settings') }">
              <settings-table />
            </b-tab>
            <b-tab v-if="showReportGroupSettings" title="Report group settings" title-link-class="report-group-settings" :title-link-attributes="{ href: buildTabUrl('report-group') }">
              <report-group-settings-table />
            </b-tab>
            <b-tab v-if="showTeams" title="Teams" :title-link-attributes="{ href: buildTabUrl('teams') }">
              <team-members-table />
            </b-tab>
          </b-tabs>
        </div>
        <div class="col-4">
          <b-tabs v-if="entityScheduleActivity !== null && !isTeams" class="schedule-details configuration-tabs" :active-tab-class="{ 'report-group-settings-table': isReportGroupSettings }" :aria-busy="loading">
            <b-tab :title="`Activity log (${filteredActivity.length})`" :title-link-class="{ 'report-group-settings': isReportGroupSettings }">
              <activity-table :activity="filteredActivity" :loading="loading" />
            </b-tab>
            <b-tab :title="`Previous reports`" v-if="!isReportGroupSettings && !isTeams && showSchedule">
              <request-table :requests="filteredRequests" :loading="loading" />
            </b-tab>
          </b-tabs>
          <b-tabs v-if="teamActivity !== null && isTeams" class="schedule-details configuration-tabs" :active-tab-class="{ 'report-group-settings-table': isReportGroupSettings }" :aria-busy="loading">
            <b-tab :title="`Activity log (${currentTeamActivity.length})`" :title-link-class="{ 'report-group-settings': isReportGroupSettings }">
              <activity-table :activity="currentTeamActivity" :loading="loading" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EntitySchedule, EntityScheduleActivityLog, EntityScheduleActivity, EntityScheduleCompletedRequest, EntityScheduleEntry } from '../store/schedule/state';
import { namespace } from 'vuex-class';
import FilterBar from '@/components/configuration/filter-bar.vue';
import ScheduleTable from '@/components/schedule/schedule-table.vue';
import { Configuration, ConfigurationEntity, ConfigurationTeam, EntityContentControlActivity, EntityContentControlActivityLog, EntitySettingsActivityLog, ReportGroupSettingsActivityLog, TeamActivity, TeamActivityLog } from '../store/configuration/state';
import { Route } from 'vue-router';
import { BTab } from 'bootstrap-vue';
import { Tenant, ReportGroup } from '../store/tenant/state';
import ContentControlTable from '@/components/configuration/content-control-table.vue';
import RequestTable from '@/components/configuration/request-table.vue';
import ActivityTable from '@/components/configuration/activity-table.vue';
import SettingsTable from '@/components/configuration/settings-table.vue';
import ReportGroupSettingsTable from '@/components/configuration/report-group-settings-table.vue';
import TeamMembersTable from '@/components/configuration/team-member-table.vue';

const configurationModule = namespace('configuration');
const scheduleModule = namespace('schedule');
const tenantModule = namespace('tenant');

@Component({
  components: {
    FilterBar,
    ScheduleTable,
    ContentControlTable,
    RequestTable,
    ActivityTable,
    SettingsTable,
    ReportGroupSettingsTable,
    TeamMembersTable,
  },
})
export default class ConfigurationPage extends Vue {
  @scheduleModule.Getter('isLoading') private scheduleLoading!: boolean;
  @configurationModule.Getter('isLoading') private configurationLoading!: boolean;
  @configurationModule.Getter public current!: Configuration | null;
  @configurationModule.Getter public currentEntity!: ConfigurationEntity | null;
  @configurationModule.State public entityContentControlActivity!: EntityContentControlActivityLog | null;
  @scheduleModule.Getter public entitySchedule!: EntitySchedule | null;
  @scheduleModule.State public entityScheduleActivity!: EntityScheduleActivityLog | null;
  @configurationModule.State public entitySettingsActivity!: EntitySettingsActivityLog | null;
  @configurationModule.State public reportGroupSettingsActivity!: ReportGroupSettingsActivityLog | null;
  @configurationModule.State public teamActivity!: TeamActivityLog | null;
  @scheduleModule.Getter('currentEntry') public selectedEntry!: EntityScheduleEntry | null;
  @scheduleModule.Getter private requests!: Array<EntityScheduleCompletedRequest>;
  @tenantModule.Getter('current') public currentTenant!: Tenant;
  @configurationModule.Getter public currentReportGroup!: ReportGroup;
  @tenantModule.Getter('currentTenantAndReportGroup') public currentTenantAndReportGroup!: { tenant: Tenant, reportGroup: ReportGroup };
  @configurationModule.Getter public currentTeam!: ConfigurationTeam | null;

  private currentTabIndex: number = 0;

  private get loading(): boolean {
    if (this.currentTabIndex > 0) {
      return this.configurationLoading;
    }

    return this.scheduleLoading;
  }

  private get showSchedule(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.canViewSchedule;
  }

  private get showContentControl(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.canViewContentControl || this.current.canEditContentControl;
  }

  private get showSettings(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.canViewSettings || this.current.canEditSettings;
  }

  private get showReportGroupSettings(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.canViewReportGroupSettings || this.current.canEditReportGroupSettings;
  }

  private get showTeams(): boolean {
    if (this.current === null) {
      return false;
    }

    return this.current.canViewTeams;
  }

  private get isReportGroupSettings(): boolean {
    if (this.current === null) {
      return false;
    }

    const option = this.options[this.currentTabIndex];

    return option === 'report-group';
  }

  private get isTeams(): boolean {
    if (this.current === null) {
      return false;
    }

    const option = this.options[this.currentTabIndex];

    return option === 'teams';
  }

  private get options(): Array<string> {
    if (this.current === null) {
      return [];
    }

    const results: Array<string> = [];

    if (this.showSchedule) {
      results.push('schedule');
    }

    if (this.showContentControl) {
      results.push('content-control');
    }

    if (this.showSettings) {
      results.push('settings');
    }

    if (this.showReportGroupSettings) {
      results.push('report-group');
    }

    if (this.showTeams) {
      results.push('teams');
    }

    return results;
  }

  public get activity(): Array<EntityContentControlActivity | EntityScheduleActivity> {
    const option = this.options[this.currentTabIndex];

    switch (option) {
      case 'schedule': return this.entityScheduleActivity?.activity || [];
      case 'content-control': return this.entityContentControlActivity?.activity || [];
      case 'settings': return this.entitySettingsActivity?.activity || [];
      case 'report-group': return this.reportGroupSettingsActivity?.activity || [];
      default: return this.entityScheduleActivity?.activity || [];
    }
  }

  public get currentTeamActivity(): Array<TeamActivity> {
    return this.teamActivity?.activity || [];
  }

  public get filteredActivity(): Array<EntityScheduleActivity> {
    if (this.selectedEntry === null) {
      return this.activity;
    }

    if (this.currentTabIndex > 0) {
      return this.activity;
    }

    return this.activity.filter((r) => r.entityId === this.selectedEntry!.id);
  }

  public get filteredRequests(): Array<EntityScheduleCompletedRequest> {
    if (this.requests == null || this.requests.length < 1) {
      return [];
    }

    return this.requests;
  }

  private buildTabUrl(option: string): string {
    const route: Route = { ...this.$route };

    return `/${route.params.tenant}/configuration/${route.params.reportGroup}/${option}`;
  }

  @Watch('options')
  public async onCurrentConfigurationChanged(): Promise<void> {
    const { settings } = this.$route.params;

    if (settings === undefined) {
      await this.setInitialTabSelection();
      return;
    }

    const options: Array<string> = [];

    if (this.current !== null && this.current.canViewSchedule) {
      options.push('schedule');
    }

    if (this.current !== null && (this.current.canViewContentControl || this.current.canEditContentControl)) {
      options.push('content-control');
    }

    if (this.current !== null && (this.current!.canViewSettings || this.current!.canEditSettings)) {
      options.push('settings');
    }

    if (this.current !== null && (this.current!.canViewReportGroupSettings || this.current!.canEditReportGroupSettings)) {
      options.push('report-group');
    }

    if (this.current !== null && this.showTeams) {
      options.push('teams');
    }

    const index = options.findIndex((o) => o === settings);

    if (index < 0) {
      await this.setInitialTabSelection();
      return;
    }

    this.currentTabIndex = index;
  }

  private async onTabActivated(newIndex: number, oldIndex: number): Promise<void> {
    const match = this.options[newIndex];

    if (this.$route.params.settings === undefined || this.$route.params.settings !== match) {
      try {
        await this.$router.push({ name: 'configuration', params: { ...this.$route.params, settings: match } });
      } catch (e) {
      }
    }
  }

  private async onTabsChanged(newTabs: Array<BTab>, oldTabs: Array<BTab>): Promise<void> {
    if (this.$route.params.settings !== undefined) {
      const index = this.options.findIndex((o) => o === this.$route.params.settings);

      this.currentTabIndex = index;
    }
  }

  private async currentTabChanged(index: number): Promise<void> {
    const option = this.options[index];

    switch (option) {
      case 'schedule': {
        this.$store.commit('schedule/setLoading', true);

        const schedule = this.$store.dispatch('schedule/getScheduleForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
        const activity = this.$store.dispatch('schedule/getScheduleActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
        const requests = this.$store.dispatch('schedule/getCompletedScheduleEntityRequestsAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id, page: 1, pageSize: 50 });

        await Promise.all([schedule, activity, requests]);

        this.$store.commit('schedule/setLoading', false);
        break;
      }
      case 'content-control': {
        this.$store.commit('configuration/setLoading', true);

        const contentControl = this.$store.dispatch('configuration/getContentControlForEntityAsync', { reportGroupId: this.currentReportGroup!.id, entityId: this.currentEntity?.id || 0 });
        const activity = this.$store.dispatch('configuration/getContentControlActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
        const requests = this.$store.dispatch('schedule/getCompletedScheduleEntityRequestsAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id, page: 1, pageSize: 50 });

        await Promise.all([contentControl, activity, requests]);

        this.$store.commit('configuration/setLoading', false);
        break;
      }
      case 'settings': {
        this.$store.commit('configuration/setLoading', true);

        const settings = this.$store.dispatch('configuration/getSettingsForEntityAsync', { reportGroupId: this.currentReportGroup!.id, entityId: this.currentEntity?.id || 0 });
        const activity = this.$store.dispatch('configuration/getSettingsActivityForEntityAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id });
        const requests = this.$store.dispatch('schedule/getCompletedScheduleEntityRequestsAsync', { entityId: this.currentEntity?.id || 0, reportGroupId: this.currentReportGroup.id, page: 1, pageSize: 50 });

        await Promise.all([settings, activity, requests]);

        this.$store.commit('configuration/setLoading', false);
        break;
      }
      case 'report-group': {
        this.$store.commit('configuration/setLoading', true);

        const settings = this.$store.dispatch('configuration/getSettingsForReportGroupAsync', { reportGroupId: this.currentReportGroup!.id });
        const activity = this.$store.dispatch('configuration/getSettingsActivityForReportGroupAsync', { reportGroupId: this.currentReportGroup.id });
        await Promise.all([settings, activity]);

        this.$store.commit('configuration/setLoading', false);
        break;
      }
      case 'teams': {
        this.$store.commit('configuration/setLoading', true);

        if (this.currentTeam !== null) {
          const members = this.$store.dispatch('configuration/getMembersForTeamAsync', { teamId: this.currentTeam.id, reportGroupId: this.currentReportGroup.id });
          const activity = this.$store.dispatch('configuration/getTeamActivityForReportGroupAsync', { teamId: this.currentTeam.id, reportGroupId: this.currentReportGroup.id });

          await Promise.all([members, activity]);
        }

        this.$store.commit('configuration/setLoading', false);
        break;
      }
      default: {
      }
    }
  }

  @Watch('currentEntity')
  private async onCurrentEntityChanged(value: ConfigurationEntity): Promise<void> {
    await this.currentTabChanged(this.currentTabIndex);
  }

  @Watch('currentTeam')
  private async onCurrentTeamChanged(value: ConfigurationTeam): Promise<void> {
    await this.currentTabChanged(this.currentTabIndex);
  }

  private async setInitialTabSelection(): Promise<void> {
    const options = this.options;

    if (options.length < 1) {
      return;
    }

    if (this.$route.params.settings === undefined || this.$route.params.settings !== this.options[0]) {
      try {
        await this.$router.push({ name: 'configuration', params: { ...this.$route.params, settings: this.options[0] } });
      } catch (e) {
      }
    }
  }
}
</script>

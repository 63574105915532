<template>
  <div class="workflow-filter-bar row align-items-center">
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Report group:</label>
        <div class="col-auto">
          <b-form-select v-model="reportGroup" @change="changeReportGroup(reportGroup)">
            <b-form-select-option :value="group" v-for="group in reportGroups" :key="group.id">{{ group.name }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div v-if="currentWorkflow.entityName" class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">{{ currentWorkflow.entityName }}:</label>
        <div class="col-auto">
          <multi-select-dropdown :value="selectedEntityIdArray" :options="entityOptions" :entity-name="currentWorkflow.entityName" @change="changeSelectedEntitiesDebounced" @clear="changeSelectedEntities([])" />
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Valuation date:</label>
        <div class="col-auto">
          <b-form-select v-model="valuationDate" @change="changeValuationDate" v-if="currentWorkflow !== null">
            <b-form-select-option v-for="date in valuationDates" :key="date.valuationDate" :value="date">{{ date.displayDate | valuationdate(currentWorkflow.dateFormat) }} &ndash; {{ valuationDateDescription(date) }}</b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="row align-items-center">
        <label class="col-auto mb-0 pr-0">Find report ID:</label>
        <div class="col-auto">
          <b-input-group>
            <template v-slot:append>
              <b-button variant="primary" @click="findWorkflowReport(findValue)">Find</b-button>
            </template>
            <b-form-input v-model="findValue" :number="true" placeholder="Report ID" @keyup.enter="findWorkflowReport(findValue)"></b-form-input>
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <b-dropdown v-if="showActions && !hideActions" :text="`Bulk actions (${selected.length})`" variant="primary" right :popper-opts="{ modifiers: { computeStyle: { gpuAcceleration: false } } }">
        <b-dropdown-item v-if="showDownloadPdf" @click="onDownloadClicked('PDF')">Download selected PDFs</b-dropdown-item>
        <b-dropdown-item v-if="showDownloadPptx" @click="onDownloadClicked('PPTX')">Download selected PPTXs</b-dropdown-item>
        <b-dropdown-item v-if="showDownloadXlsx" @click="onDownloadClicked('XLSX')">Download selected XLSXs</b-dropdown-item>
        <b-dropdown-item v-if="showFlag" @click="onFlagClicked">Flag</b-dropdown-item>
        <b-dropdown-item v-if="showUnflag" @click="onUnflagClicked">Unflag</b-dropdown-item>
        <b-dropdown-item v-if="showSendDelayedCommentary" @click="onSendDelayedCommentaryClicked">Send delayed commentary now</b-dropdown-item>
        <b-dropdown-item v-if="showApprove" @click="onApproveClicked">Approve</b-dropdown-item>
        <b-dropdown-item v-if="showReject" @click="onRejectClicked">Reject</b-dropdown-item>
        <b-dropdown-item v-if="showReturnToApprovalOne" @click="onReturnForApprovalOneClicked">Return to {{ getApprovalStageName(3) }}</b-dropdown-item>
        <b-dropdown-item v-if="showReturnToApprovalTwo" @click="onReturnForApprovalTwoClicked">Return to {{ getApprovalStageName(4) }}</b-dropdown-item>
        <b-dropdown-item v-if="showReturnToApprovalThree" @click="onReturnForApprovalThreeClicked">Return to {{ getApprovalStageName(5) }}</b-dropdown-item>
        <b-dropdown-item v-if="showReturnToApprovalFour" @click="onReturnForApprovalFourClicked">Return to {{ getApprovalStageName(6) }}</b-dropdown-item>
        <b-dropdown-item v-if="showReAquireCommentary" @click="onReaquireCommentaryClicked">Re-acquire commentary</b-dropdown-item>
        <b-dropdown-item v-if="showCancelReAquireCommentary" @click="onCancelCommentaryReaquisitionClicked">Cancel re-acquiring commentary</b-dropdown-item>
        <b-dropdown-item v-if="showResendCommentary" @click="onResendCommentaryEmailClicked">Resend commentary e-mail</b-dropdown-item>
        <b-dropdown-item v-if="showResendApprovalEmail" @click="onResendApprovalEmailClicked">Resend approval e-mail</b-dropdown-item>
        <b-dropdown-item v-if="showResendApprovalEmailForDeadline" @click="onResendApprovalEmailForDeadlineClicked">Resend approval deadline e-mail</b-dropdown-item>
        <b-dropdown-item v-if="showRunReplacement" @click="onRunReplacementClicked">Run replacement</b-dropdown-item>
        <b-dropdown-item v-if="showIgnoreNewDataWarnings" @click="onIgnoreNewDataWarningsClicked">Ignore new data warnings</b-dropdown-item>
        <b-dropdown-item v-if="showReleaseDraftData" @click="onReleaseDraftClicked">Release draft data</b-dropdown-item>
        <b-dropdown-item v-if="showRecheckData" @click="onRecheckDataClicked">Re-check data</b-dropdown-item>
        <b-dropdown-item v-if="showRerender" @click="onRerenderClicked">Re-render</b-dropdown-item>
        <b-dropdown-divider v-if="showDelete" variant="danger"></b-dropdown-divider>
        <b-dropdown-item v-if="showDelete" variant="danger" @click="onDeleteClicked">Delete</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-auto">
      <render-running-icon v-if="isDownloading" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { BvToastMixin } from '@/mixins/bv-toast';
import { namespace } from 'vuex-class';
import { TenantResponse } from '@/api/responses/tenant/tenant-response';
import { TenantGroupResponse } from '@/api/responses/tenant/tenant-group-response';
import { WorkflowDateResponse } from '@/api/responses/workflow/workflow-date-response';
import MultiSelectDropdown from '../shared/multi-select-dropdown.vue';
import { WorkflowReport, Workflow, WorkflowOption } from '@/store/workflow/state';
import VueRouter from 'vue-router';
import { kebabcase, camelcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { FindWorkflowResponse } from '../../api/responses/workflow/find-workflow-response';
import RenderRunningIcon from './workflow-report-rendering-icons/render-running.vue';
import debounce from 'lodash.debounce';

const tenantModule = namespace('tenant');
const workflowModule = namespace('workflow');

@Component({
  components: {
    RenderRunningIcon,
    MultiSelectDropdown,
  },
})
export default class WorkflowFilterBar extends Mixins(BvToastMixin) {
  @tenantModule.Getter('current') currentTenant!: TenantResponse;
  @tenantModule.Getter tenants!: Array<TenantResponse>;
  @tenantModule.Getter selectedReportGroup!: TenantGroupResponse;
  @workflowModule.Getter valuationDates!: Array<WorkflowDateResponse>;
  @workflowModule.Getter('current') currentWorkflow!: Workflow;
  @workflowModule.Getter('currentOption') currentState!: WorkflowOption;
  @workflowModule.Getter('options') currentWorkflowOptions!: Array<WorkflowOption>;
  @workflowModule.Getter('isDownloading') isDownloading!: boolean;
  @workflowModule.Getter selectedValuationDate!: WorkflowDateResponse;
  @workflowModule.Getter selectedEntityIds!: string | null;
  reportGroup: TenantGroupResponse | null = null;
  valuationDate: WorkflowDateResponse | null = null;
  showActions = false;
  selected: Array<WorkflowReport> = new Array<WorkflowReport>();
  findValue: string | number | null = null;
  entities: Array<string> | null = null;

  get entityOptions(): Array<{label: string, value: number}> {
    return this.currentWorkflow.entities.map(entity => ({
      label: entity.name,
      value: entity.entityId,
    }));
  }

  get selectedEntityIdArray(): Array<number> {
    if (this.selectedEntityIds === null) return [];

    return this.selectedEntityIds.split(',').map(x => Number(x));
  }

  get reportGroups(): Array<TenantGroupResponse> {
    return this.currentTenant.reportGroups.filter((rg) => rg.visible.all);
  }

  get showApprove(): boolean {
    return this.selected.every((r) => r.actions.canApprove);
  }

  get showReject(): boolean {
    return this.selected.every((r) => r.actions.canReject);
  }

  get showDownloadPdf(): boolean {
    return this.selected.every((r) => r.filenamePreview && r.fileTypePreview === 'PDF');
  }

  get showDownloadPptx(): boolean {
    return this.selected.every((r) => r.filenameDownload && r.fileTypeDownload === 'PPTX');
  }

  get showDownloadXlsx(): boolean {
    return this.selected.every((r) => r.filenameDownload && r.fileTypeDownload === 'XLSX');
  }

  get showFlag(): boolean {
    return this.selected.every((r) => r.actions.canFlag);
  }

  get showUnflag(): boolean {
    return this.selected.every((r) => r.actions.canUnflag);
  }

  get showSendDelayedCommentary(): boolean {
    return this.selected.every((r) => r.actions.canSendDelayedCommentary);
  }

  get showReturnToApprovalOne(): boolean {
    return this.selected.every((r) => r.actions.canReturnToApprovalOne);
  }

  get showReturnToApprovalTwo(): boolean {
    return this.selected.every((r) => r.actions.canReturnToApprovalTwo);
  }

  get showReturnToApprovalThree(): boolean {
    return this.selected.every((r) => r.actions.canReturnToApprovalThree);
  }

  get showReturnToApprovalFour(): boolean {
    return this.selected.every((r) => r.actions.canReturnToApprovalFour);
  }

  get showRecheckData(): boolean {
    return this.selected.every((r) => r.actions.canRecheckData);
  }

  get showRerender(): boolean {
    return this.selected.every((r) => r.actions.canReRender);
  }

  get showReAquireCommentary(): boolean {
    return this.selected.every((r) => r.actions.canReAcquireCommentary);
  }

  get showCancelReAquireCommentary(): boolean {
    return this.selected.every((r) => r.actions.canCancelReAcquireCommentary);
  }

  get showResendCommentary(): boolean {
    return this.selected.every((r) => r.actions.canResendCommentary);
  }

  get showResendApprovalEmail(): boolean {
    return this.selected.every((r) => r.actions.canResendApprovalEmail);
  }

  get showResendApprovalEmailForDeadline(): boolean {
    return this.selected.every((r) => r.actions.canResendApprovalEmailForDeadline);
  }

  get showReleaseDraftData(): boolean {
    return this.selected.every((r) => r.actions.canReleaseDraftData);
  }

  get showDelete(): boolean {
    return this.selected.every((r) => r.actions.canDelete);
  }

  get showRunReplacement(): boolean {
    return this.selected.every((r) => r.actions.canRunReplacement);
  }

  get showIgnoreNewDataWarnings(): boolean {
    return this.selected.every((r) => r.actions.canIgnoreNewDataWarnings);
  }

  get hideActions(): boolean {
    return !this.currentWorkflow.hasBatchActionsAvailable || (this.selected.length < 1 && this.selected.some((r) => !this.canShowActions(r)));
  }

  created() {
    this.$root.$on('all-workflow-reports-selected', this.onAllWorkflowsSelected);
    this.$root.$on('all-workflow-reports-deselected', this.onAllWorkflowsDeselected);
    this.$root.$on('workflow-report-selected', this.onWorkflowReportSelected);
    this.$root.$on('workflow-report-deselected', this.onWorkflowReportDeselected);
  }

  beforeDestroy() {
    this.$root.$off('all-workflow-reports-selected', this.onAllWorkflowsSelected);
    this.$root.$off('all-workflow-reports-deselected', this.onAllWorkflowsDeselected);
    this.$root.$off('workflow-report-selected', this.onWorkflowReportSelected);
    this.$root.$off('workflow-report-deselected', this.onWorkflowReportDeselected);
  }

  async mounted(): Promise<void> {
    this.reportGroup = this.selectedReportGroup;
    this.valuationDate = this.selectedValuationDate;
  }

  @Watch('selectedReportGroup')
  private onSelectedReportGroupChanged(reportGroup: TenantGroupResponse): void {
    this.reportGroup = this.selectedReportGroup;
  }

  @Watch('selectedValuationDate')
  private onSelectedValuationDateChanged(valuationDate: WorkflowDateResponse): void {
    this.valuationDate = this.selectedValuationDate;
  }

  canShowActions(item: WorkflowReport): boolean {
    if (item.replaced) {
      return false;
    }

    if (Object.keys(item.actions).length < 1) {
      return false;
    }

    if (!Object.values(item.actions).some((a) => a)) {
      return false;
    }

    if (item.renderingStatus === 'RenderPending' && item.actions.canReRender) {
      return true;
    }

    if (item.renderingStatus !== 'Blank' && item.renderingStatus !== 'Error') {
      return false;
    }

    return true;
  }

  async changeReportGroup(reportGroup: TenantGroupResponse): Promise<void> {
    if (this.$route.params.reportGroup === undefined || this.$route.params.reportGroup !== kebabcase(reportGroup.name)) {
      try {
        await this.$router.push({ name: 'workflow', params: { ...this.$route.params, reportGroup: kebabcase(reportGroup.name) } });
      } catch (e) {
        // NOTE(Dan): In the router beforeEach we may get redirected to a different URL, e.g. we may calculate a new route based on configuration for the users initial page
        if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
          return;
        }

        throw e;
      }
    }

    this.onAllWorkflowsDeselected();
  }

  changeSelectedEntitiesDebounced = debounce(this.changeSelectedEntities, 1000);
  async changeSelectedEntities(entities: Array<string> | null): Promise<void> {
    await this.$store.dispatch('workflow/getWorkflowPageAsync', { status: this.currentState!.id, reportGroupId: this.currentWorkflow!.reportGroupId, valuationDate: this.selectedValuationDate!.valuationDate, page: 1, pageSize: this.currentWorkflow!.itemsPerPage, entityIds: entities?.join(',') });
    await this.$store.dispatch('tenant/setCurrentReportGroupEntityIdsAsync', { id: this.currentWorkflow.reportGroupId, name: this.reportGroup?.name, tenantId: this.currentTenant.id, tenantName: this.currentTenant.name, entityIds: entities?.join(',') });
  }

  async changeValuationDate(valuationDate: WorkflowDateResponse): Promise<void> {
    const dateParam = kebabcase(date(valuationDate.displayDate, this.currentWorkflow.dateFormat));
    if (this.$route.params.valuationDate === undefined || this.$route.params.valuationDate !== dateParam) {
      try {
        await this.$router.push({ name: 'workflow', params: { ...this.$route.params, valuationDate: dateParam } });
      } catch (e) {
        // NOTE(Dan): In the router beforeEach we may get redirected to a different URL, e.g. we may calculate a new route based on configuration for the users initial page
        if (VueRouter.isNavigationFailure(e, VueRouter.NavigationFailureType.redirected)) {
          return;
        }

        throw e;
      }
    }
  }

  valuationDateDescription(date: WorkflowDateResponse): string {
    if (Object.values(date.options).every((v) => v! < 1)) {
      return '';
    }

    const parts: Array<string> = [];
    let waiting: number = 0;
    let pendingApproval: number = 0;
    let completed: number = 0;

    const pendingApprovalStages = [3, 4, 5, 6];

    for (const key of Object.keys(date.options)) {
      if (key.startsWith('wait')) {
        const value = date.options[key] || 0;
        waiting += value;
      }

      if (this.currentWorkflowOptions !== null) {
        const isPendingApprovalsStage = this.currentWorkflowOptions
          .filter((item) => {
            return camelcase(item.name) === key && pendingApprovalStages.includes(item.id);
          }).length > 0;

        if (isPendingApprovalsStage) {
          const value = date.options[key] || 0;
          pendingApproval += value;
        }
      }

      if (key.startsWith('complete')) {
        const value = date.options[key] || 0;
        completed += value;
      }
    }

    if (waiting > 0) {
      parts.push(`${waiting} waiting`);
    }

    if (pendingApproval > 0) {
      parts.push(`${pendingApproval} pending approval`);
    }

    if (completed > 0) {
      parts.push(`${completed} completed`);
    }

    const result = parts.join(', ');

    return result;
  }

  private onAllWorkflowsSelected(reports: Array<WorkflowReport>): void {
    this.selected = [];
    const filtered = reports.filter((item) => !item.replaced && Object.keys(item.actions).length > 0 && Object.values(item.actions).some((a) => a));
    this.selected.push(...filtered);
    this.showActions = this.selected.length > 0;
  }

  private onAllWorkflowsDeselected(): void {
    this.selected = [];
    this.showActions = false;
    this.selected.length = 0;
  }

  private onWorkflowReportSelected(report: WorkflowReport): void {
    if (!this.selected.some((r) => r.id === report.id)) {
      this.selected.push(report);
      this.showActions = this.selected.length > 0;
    }
  }

  private onWorkflowReportDeselected(report: WorkflowReport): void {
    const index = this.selected.findIndex((r) => r.id === report.id);

    if (index < 0) {
      return;
    }

    this.selected.splice(index, 1);
    this.showActions = this.selected.length > 0;
  }

  async findWorkflowReport(id: string | number): Promise<void> {
    try {
      const result = await this.$store.dispatch('workflow/findWorkflowReportAsync', id) as FindWorkflowResponse;
      const currentTenant = this.currentTenant;
      const params = { ...this.$route.params };
      const currentReportGroup = this.selectedReportGroup;
      const currentState = this.currentState;
      let paramsChanged: boolean = false;
      let matchingTenant = null;

      if (currentTenant.id !== result.tenantId) {
        matchingTenant = this.tenants.find((t) => t.id === result!.tenantId) || null;

        if (matchingTenant === null) {
          throw new Error('No matching tenant found.');
        }

        params.tenant = kebabcase(matchingTenant.name);
        paramsChanged = true;
      }

      if (currentReportGroup.id !== result.reportGroupId) {
        params.reportGroup = kebabcase(result.reportGroup);
        paramsChanged = true;
      }

      const currentValuationDate = this.selectedValuationDate;

      if (currentValuationDate!.valuationDate !== result.valuationDate) {
        params.valuationDate = kebabcase(date(result.displayDate, result.dateFormat));
        paramsChanged = true;
      }

      if (currentState.id !== result.stateId) {
        params.option = kebabcase(result.state);
        paramsChanged = true;
      }

      if (params.reportId !== id) {
        params.reportId = id.toString();
        paramsChanged = true;
      }

      if (paramsChanged) {
        try {
          await this.$router.push({ name: 'workflow', params: params });
        } catch (e) {
        }
      }
    } catch (e) {
      this.showErrorToast(`Could not find report '${id}'. Please try again.`);
    }

    this.findValue = null;
  }

  getApprovalStageName(approvalStageID: number): string {
    if (this.currentWorkflowOptions === null) {
      return 'Unkown';
    }

    const approvalStageName = this.currentWorkflowOptions.find(option => option.id === approvalStageID)?.name;

    if (approvalStageName === undefined) {
      return 'Unkown';
    } else {
      return approvalStageName;
    }
  }

  onApproveClicked() {
    this.$root.$emit('bulk-workflow-report-approve-clicked', this.selected);
  }

  onRejectClicked() {
    this.$root.$emit('bulk-workflow-report-reject-clicked', this.selected);
  }

  onDownloadClicked(type: 'PDF' | 'PPTX' | 'XLSX') {
    this.$root.$emit('bulk-workflow-report-download', this.selected, type);
  }

  onFlagClicked() {
    this.$root.$emit('bulk-workflow-report-flag-clicked', this.selected);
  }

  onUnflagClicked() {
    this.$root.$emit('bulk-workflow-report-unflag-clicked', this.selected);
  }

  onSendDelayedCommentaryClicked() {
    this.$root.$emit('bulk-workflow-report-send-delayed-commentary-clicked', this.selected);
  }

  onCancelCommentaryReaquisitionClicked() {
    this.$root.$emit('bulk-workflow-report-cancel-commentary-reaquisition-clicked', this.selected);
  }

  onDeleteClicked() {
    this.$root.$emit('bulk-workflow-report-delete-clicked', this.selected);
  }

  onReaquireCommentaryClicked() {
    this.$root.$emit('bulk-workflow-report-reacquire-commentary-clicked', this.selected);
  }

  onRerenderClicked() {
    this.$root.$emit('bulk-workflow-report-rerender-clicked', this.selected);
  }

  onRecheckDataClicked() {
    this.$root.$emit('bulk-workflow-report-recheck-data-clicked', this.selected);
  }

  onReleaseDraftClicked() {
    this.$root.$emit('bulk-workflow-report-release-draft-clicked', this.selected);
  }

  onResendCommentaryEmailClicked() {
    this.$root.$emit('bulk-workflow-report-resend-commentary-clicked', this.selected);
  }

  onResendApprovalEmailClicked() {
    this.$root.$emit('bulk-workflow-report-resend-approval-clicked', this.selected);
  }

  onResendApprovalEmailForDeadlineClicked() {
    this.$root.$emit('bulk-workflow-report-resend-approval-for-deadline-clicked', this.selected);
  }

  onReturnForApprovalOneClicked() {
    this.$root.$emit('bulk-workflow-report-return-for-approval-one-clicked', this.selected);
  }

  onReturnForApprovalTwoClicked() {
    this.$root.$emit('bulk-workflow-report-return-for-approval-two-clicked', this.selected);
  }

  onReturnForApprovalThreeClicked() {
    this.$root.$emit('bulk-workflow-report-return-for-approval-three-clicked', this.selected);
  }

  onReturnForApprovalFourClicked() {
    this.$root.$emit('bulk-workflow-report-return-for-approval-four-clicked', this.selected);
  }

  onRunReplacementClicked() {
    this.$root.$emit('bulk-workflow-report-run-replacement-clicked', this.selected);
  }

  onIgnoreNewDataWarningsClicked() {
    this.$root.$emit('bulk-workflow-report-ignore-new-data-warnings-clicked', this.selected);
  }
}
</script>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';
import Chart, { ChartOptions, DoughnutModel, ChartDataSets, ChartData, ChartLegendLabelItem } from 'chart.js';
import { DashboardStatisticsReportGroup } from '../../store/dashboard/state';
import { kebabcase } from '@/utilities/text.utils';
import { date } from '@/filters/date';
import { numberToWords } from '@/filters/number-to-words';
import { RawLocation } from 'vue-router';
import { ReportGroup, Tenant } from '../../store/tenant/state';
import { namespace } from 'vuex-class';

const tenantModule = namespace('tenant');

type ChartElement = { hidden: boolean, _chart: Chart, _datasetIndex: number, _index: number, _model: DoughnutModel & { label: string }, _options: {} };

@Component({
  extends: Doughnut,
})
export default class ReportGroupChart extends Vue {
  @tenantModule.Getter('current') private currentTenant!: Tenant | null;
  @Prop({ type: Object, required: true }) private data!: DashboardStatisticsReportGroup;
  @Prop({ type: Object, default: null }) private options!: ChartOptions | null;

  private originalValues: Array<number> = [];

  private get reportGroup(): ReportGroup | null {
    if (this.currentTenant === null) {
      return null;
    }

    return this.currentTenant.reportGroups.find((rg) => rg.name === this.data.name) || null;
  }

  private get colours() {
    const colours: Array<string> = this.data.series.map((l) => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);

      return `rgb(${r}, ${g}, ${b})`;
    });

    return colours;
  }

  private get formattedData() {
    const innerData = this.data.series.flatMap((s, i) => {
      return {
        label: s.name,
        data: s.values.map((v) => v.value).reduce((prev, next) => {
          return prev + next;
        }, 0),
        backgroundColor: s.colour !== null ? `rgb(${s.colour.r}, ${s.colour.g}, ${s.colour.b})` : this.colours[i % 4],
        labels: s.values.map((v) => v.category),
      };
    });

    const outerData = this.data.series.flatMap((s, i) => {
      return s.values.map((v, j) => {
        return {
          label: v.category,
          data: v.value,
          backgroundColor: v.categoryColour !== null ? `rgb(${v.categoryColour.r}, ${v.categoryColour.g}, ${v.categoryColour.b})` : this.colours[i % 4],
          labels: [v.label],
        };
      });
    });

    const dataSets: Array<ChartDataSets & { labels: Array<string>, categories: Array<string> }> = [{
      data: outerData.map((d) => d.data),
      backgroundColor: outerData.flatMap((d) => d.backgroundColor),
      labels: [...new Set<string>(outerData.flatMap((d) => d.labels))],
      // weight: 2,
      categories: innerData.flatMap((d) => d.labels),
    }, {
      data: innerData.flatMap((d) => d.data),
      backgroundColor: [...new Set<string>(innerData.flatMap((d) => d.backgroundColor))],
      labels: [...new Set<string>(innerData.flatMap((d) => d.labels))],
      categories: innerData.flatMap((d) => d.labels),
    }];

    const data: ChartData = {
      labels: [...new Set<string>(outerData.flatMap((d) => d.labels))],
      datasets: dataSets,
    };

    return data;
  }

  private get formattedOptions(): ChartOptions {
    const self = this;

    return this.options || {
      cutoutPercentage: 35,
      elements: {
        arc: {
          borderWidth: 1,
        },
      },
      showLines: false,
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'right',
        align: 'start',
        labels: {
          generateLabels: function (chart: Chart): Array<ChartLegendLabelItem> {
            const original = Chart.defaults.pie.legend.labels.generateLabels;
            const labels = original.call(this, chart) as Array<ChartLegendLabelItem>;

            labels.map((label) => {
              const value = chart.data.datasets![0]!.data![label.index!] as number;
              label.text += ` (${value})`;
            });

            return labels;
          },
        },
        onClick: function (e: MouseEvent, legendItem: Chart.ChartLegendLabelItem) {
          const index = legendItem.index!;
          const chart = (this as any).chart as Chart;
          const outerMeta = chart.getDatasetMeta(0);
          const element = outerMeta.data[index!];
          const dataset = self.formattedData.datasets![element._datasetIndex] as ChartDataSets & { labels: Array<string>, categories: Array<string> };
          const value = dataset.data![element._index] as number;
          const category = dataset.categories[element._index];
          const innerDataset = chart.data.datasets![1];
          const item = self.data.series.findIndex((s) => s.name === category)!;

          if (element.hidden) {
            (innerDataset.data![item] as number) += self.originalValues[index];
          } else {
            self.originalValues[index] = value;
            (innerDataset.data![item] as number) -= self.originalValues[index];
          }

          element.hidden = !element.hidden;

          chart.update();
        },
      },
      hover: {
        onHover: (e: MouseEvent, activeElements: Array<ChartElement>) => {
          const target = this.$el.getElementsByTagName('canvas')[0];
          target.style.cursor = activeElements.length > 0 && activeElements[0]._datasetIndex === 0 ? 'pointer' : 'default';
        },
      },
      tooltips: {
        callbacks: {
          label: (item: any, data: any) => {
            const set = data.datasets[item.datasetIndex];
            const index = item.index;

            return set.labels[index] + `: ${set.data[index]}`;
          },
        },
      },
      onClick: async (e?: MouseEvent, activeElements?: Array<ChartElement>) => {
        if (activeElements === undefined || activeElements.length < 1) {
          return;
        }

        const activeElement = activeElements[0];

        if (activeElement._datasetIndex !== 0) {
          return;
        }

        const dataset = activeElement._chart.data.datasets![activeElement._datasetIndex] as ChartDataSets & { labels: Array<string>, categories: Array<string> };
        const label = dataset.labels[activeElement._index];
        let category = dataset.categories[activeElement._index];

        if (category.toLowerCase() === 'approval') {
          category = label;
        }

        // NOTE(Dan): We need to do this to clear the current state to prevent the cascading watchers from interupting
        //            or overwritting the information we are trying to load by going straight to the route.
        //            This is less than ideal and really, we should fixup how these watchers etc are used on the workflow page
        //            as it's rather broken currently due to multiple changes and "races".
        await this.$store.commit('workflow/reset');

        const route: RawLocation = { name: 'workflow', params: { ...this.$route.params, reportGroup: kebabcase(this.data.name), valuationDate: kebabcase(date(this.data.displayDate, this.data.dateFormat)), option: kebabcase(category) } };
        await this.$router.push(route);
      },
    };
  }

  public mounted(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }

  @Watch('data')
  private onDataChanged(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }

  @Watch('options', { deep: true })
  private onOptionsChanged(): void {
    // @ts-ignore
    this.renderChart(this.formattedData, this.formattedOptions);
  }
}
</script>

export class State {
  public current: SupportConfiguration | null = null;
  public loading: boolean = false;
  public pages: Array<SupportTicketPage> = [];
  public ticket: SupportTicketDetails | null = null;
  public attachments: Array<SupportTicketAttachment> | null = null;
  public messages: SupportTicketMessages | null = null;
  public notes: SupportTicketMessages | null = null;
  public activity: SupportTicketActivity | null = null;
  public status: number = -1;
  public openingTicketCorrelationId: string | null = null;
  public openingTicketId: number | null = null;
  public filters: SupportTicketFilters = { id: null, tenant: null, priority: null, createdBy: null, assignedTo: null, subject: null };
}

export interface SupportConfiguration {
  categories: Array<Category>;
  issueTypes: Array<IssueType>;
  priorities: Array<Priority>;
  status: Array<Status>;
  agents: Array<Agent>;
  users: Array<User>;
  valuationDates: Array<ValuationDate>;
  pageSize: number | null;
}

export interface Category {
  id: number;
  name: string;
}

export interface IssueType {
  id: number;
  name: string;
}

export interface Priority {
  id: number;
  name: string;
}

export interface Status {
  id: number;
  name: string;
  count: number;
}

export interface Agent {
  id: number;
  name: string;
}

export interface User {
  id: number;
  name: string;
  tenantId: number;
}

export interface ValuationDate {
  valuationDate: string;
  displayDate: string | null;
}

export interface SupportTicketPage {
  page: number;
  pageSize: number;
  tickets: Array<SupportTicket>;
}

export interface SupportTicket {
  id: number;
  status: string;
  priority: string;
  issueType: string;
  category: string;
  subject: string;
  details: string | null;
  lastMessage: string | null;
  tenant: string | null;
  assignedTo: string | null;
  createdDate: string;
  lastUpdated: string | null;
  createdBy: string;
  reportGroup: string;

  actions: {
    canReply: boolean,
    canMarkAsResolved: boolean,
    canReopen: boolean,
    canClose: boolean,
    canPutOnHold: boolean,
    canMoveToDevelopment: boolean,
    canReturnToCustomer: boolean,
    canReturnToSupport: boolean,
    isReadyForTesting: boolean,
    isReadyForRelease: boolean,
  };
}

export interface SupportTicketDetails {
  id: number;
  status: string;
  priorityId: number;
  priority: string;
  issueTypeId: number;
  issueType: string;
  categoryId: number;
  category: string;
  subject: string;
  details: string;
  tenantId: number | null;
  assignedToId: number | null;
  assignedTo: string | null;
  createdDate: string;
  createdBy: string;
  reportGroup: string;
  state: string;
  stateId: number;
  valuationDate: string | null;
  reportId: number | null;
  ccEmailAddress: string | null;

  actions: {
    canReply: boolean,
    canMarkAsResolved: boolean,
    canReopen: boolean,
    canClose: boolean,
    canPutOnHold: boolean,
    canMoveToDevelopment: boolean,
    canReturnToCustomer: boolean,
    canReturnToSupport: boolean,
    isReadyForTesting: boolean,
    isReadyForRelease: boolean,
  };
}

export interface SupportTicketMessages {
  ticketId: number;
  messages: Array<SupportTicketMessage>;
}

export interface SupportTicketMessage {
  createdBy: string;
  comment: string | null;
  status: string | null;
  timestamp: string;
  userType: 'Customer' | 'Agent' | 'Unknown';
  supportIssueDetailID: number;
  editable: boolean;
}

export interface SupportTicketActivity {
  ticketId: string;
  activities: Array<SupportTicketActivityEntryGroup>;
}

export interface SupportTicketActivityEntry {
  name: string;
  description: string;
  timestamp: string;
}

export interface SupportTicketActivityEntryGroup {
  timestamp: string;
  entries: Array<SupportTicketActivityEntry>;
}

export interface SupportTicketAttachment {
  id: number;
  ticketId: string;
  name: string;
  contentType: string;
  length: number;
  createdDate: string;
  imageData: string;
}

export interface SupportTicketFilters {
  id: string | null;
  tenant: string | null;
  priority: string | null;
  createdBy: string | null;
  assignedTo: string | null;
  subject: string | null;
}
